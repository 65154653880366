@tailwind base;
@tailwind components;
@tailwind utilities;

table {
    /* font-family: arial, sans-serif; */
    width: 100%;
    display: table;
    white-space: nowrap;
}

td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 4px 6px 4px 6px;
}

p a, li a {
    @apply underline text-blue-600 hover:text-blue-800
 }